define("additive-mc/controllers/instance/campaigns/ad/content-ads/index/content-ad", ["exports", "@ember/controller", "@ember/object/computed", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "ember-animated", "additive-mc/validations/advertisement"], function (_exports, _controller, _computed, _object, _service, _tracking, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _emberAnimated, _advertisement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceCampaignsAdFacebookAdsFacebookAdController = _exports.default = (_dec = (0, _computed.not)('model.facebookContentAd.isPaused'), _dec2 = (0, _object.computed)('model.facebookContentAd.optimizationEvent', 'meta.optimizationEvents.[]'), _dec3 = (0, _object.computed)('model.facebookContentAd.targetAudience', 'meta.targetAudiences.[]'), _dec4 = (0, _object.computed)('model.facebookContentAd.{budget.amount,endDate,startDate,budgetSpend.value}', 'targetAudience'), _dec5 = (0, _emberConcurrency.task)(function* () {}), _dec6 = (0, _emberConcurrency.task)(function* () {
    if (this.model.facebookContentAd.isExpired || this.model.facebookContentAd.isArchived) {
      this.pendingDepictionUpdates = null;
      this.showUpdateNotification = false;
      this.pendingDepictionValidations = null;
      this.showPendingDepictionValidations = false;
      return;
    }
    const adapter = this.store.adapterFor('advertisement');
    const url = adapter.urlForFindAll('advertisement', {
      adapterOptions: this.adapterOptions
    });
    const endpoint = `${url}/${this.model.facebookContentAd.id}/pendingDepictionUpdates`;
    const response = yield this.authenticatedFetch.fetch(endpoint);
    const {
      pendingDepictionUpdates,
      validations
    } = yield response.json();
    this.pendingDepictionUpdates = pendingDepictionUpdates;
    this.pendingDepictionValidations = validations;
    this.showPendingDepictionValidations = (validations === null || validations === void 0 ? void 0 : validations.length) > 0;
    this.showUpdateNotification = (pendingDepictionUpdates === null || pendingDepictionUpdates === void 0 ? void 0 : pendingDepictionUpdates.length) > 0 && (validations === null || validations === void 0 ? void 0 : validations.length) === 0;
  }), _dec7 = (0, _emberConcurrency.task)(function* () {
    if (!this.model.facebookContentAd.isDraft) {
      return;
    }
    const response = yield this.model.facebookContentAd.validate(this.adapterOptions);
    if (response.ok) {
      this.isValid = true;
      return;
    }
    const {
      validations
    } = yield response.json();
    this.isValid = (validations === null || validations === void 0 ? void 0 : validations.length) > 0 ? false : true;
    this.validations = validations;
  }), _dec8 = (0, _emberConcurrency.task)(function* () {
    try {
      yield this.model.facebookContentAd.destroyRecord({
        adapterOptions: this.adapterOptions
      });
      this.router.transitionTo('instance.campaigns.ad.content-ads', this.campaignId);
      yield this.uiFilter.reset('content-ads');
      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('global.toast.success.savedChanges')
      });
    } catch (e) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toast.error.savedChanges')
      });
    }
  }), _class = class InstanceCampaignsAdFacebookAdsFacebookAdController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "store", _descriptor5, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor6, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor7, this);
      _initializerDefineProperty(this, "uiFilter", _descriptor8, this);
      _initializerDefineProperty(this, "uiToast", _descriptor9, this);
      _initializerDefineProperty(this, "isNotPaused", _descriptor10, this);
      _initializerDefineProperty(this, "campaignId", _descriptor11, this);
      _initializerDefineProperty(this, "model", _descriptor12, this);
      _initializerDefineProperty(this, "campaignGoal", _descriptor13, this);
      _initializerDefineProperty(this, "validations", _descriptor14, this);
      _initializerDefineProperty(this, "isValid", _descriptor15, this);
      _initializerDefineProperty(this, "isLoading", _descriptor16, this);
      _initializerDefineProperty(this, "isRenameDialog", _descriptor17, this);
      _initializerDefineProperty(this, "depictionMeta", _descriptor18, this);
      _initializerDefineProperty(this, "pendingDepictionUpdates", _descriptor19, this);
      _initializerDefineProperty(this, "showUpdateNotification", _descriptor20, this);
      _initializerDefineProperty(this, "showPendingDepictionValidations", _descriptor21, this);
      _initializerDefineProperty(this, "isUpdateDialog", _descriptor22, this);
      _initializerDefineProperty(this, "isPublishDialog", _descriptor23, this);
      _defineProperty(this, "_animatedTransition", _emberAnimated.fade);
      /**
       * Fetches and returns the depictions meta
       * Is replaced in setupController by task of parent controller
       *
       * @function getDepictionMeta
       * @type {Task}
       */
      _initializerDefineProperty(this, "getDepictionMeta", _descriptor24, this);
      _initializerDefineProperty(this, "getDepictionUpdates", _descriptor25, this);
      _initializerDefineProperty(this, "validate", _descriptor26, this);
      _initializerDefineProperty(this, "deleteAd", _descriptor27, this);
    }
    get showNotification() {
      return this.model.facebookContentAd.isDraft && !this.isValid && !this.validate.isRunning;
    }
    get validationProperties() {
      return ['endDate', 'targetAudience', 'budget.type', 'budget.amount', 'depictions', 'language'];
    }
    get adapterOptions() {
      return {
        campaignId: this.campaignId,
        type: 'content-ads'
      };
    }
    get isActionsDisabled() {
      var _this$model;
      return this.isNotPaused && ((_this$model = this.model) === null || _this$model === void 0 || (_this$model = _this$model.facebookContentAd) === null || _this$model === void 0 ? void 0 : _this$model.isPublic);
    }
    get isReadOnly() {
      return this.currentUser.isReadOnly;
    }
    get selectedDepictions() {
      var _this$model$facebookC;
      if (!((_this$model$facebookC = this.model.facebookContentAd) !== null && _this$model$facebookC !== void 0 && _this$model$facebookC.depictions)) {
        return [];
      }
      const selectedDepictionIds = this.model.facebookContentAd.depictions;

      // Get selected depictions by their IDs
      return selectedDepictionIds.map(depictionId => {
        return this.model.depictions.find(depiction => depiction.id == depictionId).toJSON({
          includeId: true
        });
      });
    }
    get targetLanguages() {
      var _this$model$facebookC2, _this$meta;
      if (!((_this$model$facebookC2 = this.model.facebookContentAd) !== null && _this$model$facebookC2 !== void 0 && _this$model$facebookC2.targetLanguages) || !((_this$meta = this.meta) !== null && _this$meta !== void 0 && _this$meta.availableLanguages)) {
        return null;
      }
      let languages = [];
      this.model.facebookContentAd.targetLanguages.map(language => {
        const foundLanguage = this.meta.availableLanguages.find(availableLangauge => availableLangauge.value === language);
        if (foundLanguage) {
          languages.push(foundLanguage.label);
        }
      });
      return languages.join(', ');
    }
    get publishChangeset() {
      const validation = (0, _advertisement.facebookAdPublishValidation)(this.intl);
      return new _emberChangeset.default(this.model.facebookContentAd, (0, _emberChangesetValidations.default)(validation), validation);
    }
    get campaignOptimizationEvent() {
      var _this$meta2;
      if (!this.model.facebookContentAd.optimizationEvent) return null;
      const campaignOptimizationEvent = (_this$meta2 = this.meta) === null || _this$meta2 === void 0 || (_this$meta2 = _this$meta2.optimizationEvents) === null || _this$meta2 === void 0 ? void 0 : _this$meta2.find(campaignOptimizationEvent => campaignOptimizationEvent.value === this.model.facebookContentAd.optimizationEvent);
      return campaignOptimizationEvent === null || campaignOptimizationEvent === void 0 ? void 0 : campaignOptimizationEvent.label;
    }
    get targetAudience() {
      var _this$meta3;
      if (!this.model.facebookContentAd.targetAudience) return null;
      const targetAudience = (_this$meta3 = this.meta) === null || _this$meta3 === void 0 || (_this$meta3 = _this$meta3.targetAudiences) === null || _this$meta3 === void 0 ? void 0 : _this$meta3.find(targetAudience => targetAudience.value === this.model.facebookContentAd.targetAudience);
      return targetAudience === null || targetAudience === void 0 ? void 0 : targetAudience.label;
    }
    get showContentInfo() {
      const {
        budget,
        endDate,
        startDate,
        budgetSpend
      } = this.model.facebookContentAd;
      return (budget === null || budget === void 0 ? void 0 : budget.amount) || endDate || startDate || (budgetSpend === null || budgetSpend === void 0 ? void 0 : budgetSpend.value) || this.targetAudience;
    }
    onDidInsert() {
      this.validate.perform();
      this.getDepictionUpdates.perform();
    }
    async modelAction(publishAction) {
      let toast;
      try {
        this.isLoading = true;
        toast = this.uiToast.showToast({
          title: this.intl.t(`adCampaigns.facebookContentAds.publish.loading.${publishAction}`),
          description: this.intl.t('adCampaigns.facebookContentAds.publish.loading.text'),
          type: 'loading',
          duration: -1
        });
        const response = await this.model.facebookContentAd[publishAction](this.adapterOptions);
        if (!(response !== null && response !== void 0 && response.ok)) {
          const {
            message
          } = await response.json();
          throw new Error(message);
        }
        this.uiToast.destroyToast(toast);
        const advertisement = await response.json();
        const serializer = this.store.serializerFor('advertisement');
        serializer.normalizeResponse(null, null, advertisement);
        this.store.pushPayload(advertisement);
        this.isPublishDialog = false;
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
        this.getDepictionUpdates.perform();
      } catch (e) {
        this.uiToast.destroyToast(toast);
        this.uiDialog.showError(null, e.message);
      } finally {
        this.isLoading = false;
      }
    }
    onPublish() {
      const publishAction = this.model.facebookContentAd.isDraft ? 'publish' : 'unpublish';
      this.uiDialog.showConfirm(this.intl.t(`adCampaigns.facebookContentAds.${publishAction}.dialog.title`), this.intl.t(`adCampaigns.facebookContentAds.${publishAction}.dialog.description`), () => this.modelAction(publishAction), this.intl.t(`global.actions.${publishAction}`), false);
    }
    onDelete() {
      if (this.isReadOnly) {
        return;
      }
      this.uiDialog.showConfirm(this.intl.t('adCampaigns.facebookContentAds.delete.title'), this.intl.t('adCampaigns.facebookContentAds.delete.description'), async () => {
        await this.delete();
      }, this.intl.t('global.actions.delete'), true, true);
    }
    async delete() {
      await this.deleteAd.perform();
    }
    onArchive() {
      if (this.isReadOnly) {
        return;
      }
      this.uiDialog.showConfirm(this.intl.t('adCampaigns.facebookContentAds.archive.title'), this.intl.t('adCampaigns.facebookContentAds.archive.description'), () => this.archive(), this.intl.t('global.actions.archive'), false, true);
    }
    async archive() {
      try {
        const advertisement = await this.model.facebookContentAd.archive(this.adapterOptions);
        const serializer = this.store.serializerFor('advertisement');
        serializer.normalizeResponse(null, null, advertisement);
        this.store.pushPayload(advertisement);
        this.getDepictionUpdates.perform();
      } catch (e) {
        this.uiDialog.showError(null, e.message);
      }
    }
    openRenameDialog() {
      if (this.isReadOnly) {
        return;
      }
      this.isRenameDialog = true;
    }
    onUpdate() {
      // Check if there are any depiction updates left
      this.getDepictionUpdates.perform();
      this.isUpdateDialog = false;
    }
    back() {
      this.router.transitionTo('instance.campaigns.ad.content-ads');
    }
    openEdit() {
      this.router.transitionTo('instance.campaigns.ad.content-ads.index.content-ad.edit');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "uiFilter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isNotPaused", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "campaignId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "model", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "campaignGoal", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "validations", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "isValid", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "isRenameDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "depictionMeta", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "pendingDepictionUpdates", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "showUpdateNotification", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "showPendingDepictionValidations", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "isUpdateDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "isPublishDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "campaignOptimizationEvent", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "campaignOptimizationEvent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "targetAudience", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "targetAudience"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showContentInfo", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "showContentInfo"), _class.prototype), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "getDepictionMeta", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "getDepictionUpdates", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "validate", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "modelAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "modelAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onPublish", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onPublish"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDelete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDelete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "deleteAd", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onArchive", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onArchive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "archive", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "archive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openRenameDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openRenameDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "back", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "back"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openEdit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openEdit"), _class.prototype), _class);
});