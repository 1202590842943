define("additive-mc/models/email-campaign", ["exports", "additive-mc/config/environment", "@ember-data/model", "@ember/service", "ember-concurrency", "rsvp", "additive-mc/models/campaign"], function (_exports, _environment, _model, _service, _emberConcurrency, _rsvp, _campaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let EmailCampaign = _exports.default = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('amc-configurations'), _dec3 = (0, _model.attr)('boolean'), _dec4 = (0, _model.attr)('date'), _dec5 = (0, _model.attr)('boolean'), _dec6 = (0, _model.attr)('date'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)(), _dec9 = (0, _model.hasMany)('email-series', {
    async: false
  }), _dec10 = (0, _model.belongsTo)('email-campaign-contribution', {
    async: false
  }), _dec11 = (0, _model.belongsTo)('email-campaign-voucher', {
    async: false
  }), _dec12 = (0, _model.belongsTo)('email-campaign-landingpage', {
    async: false
  }), _dec13 = (0, _emberConcurrency.task)(function () {
    var _this = this;
    let locale = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'de';
    return function* () {
      try {
        var _this$meta;
        if ((_this$meta = _this.meta) !== null && _this$meta !== void 0 && _this$meta[locale]) {
          return _this.meta[locale];
        }
        let endpoint = `${_environment.default.APP.apiBaseHost}/${_this.organizationSlug}/organisation-campaigns/email/${_this.id}/meta`;
        if (_this.isNewStructure) {
          endpoint = `${_environment.default.APP.apiBaseHostV2}/${_this.organizationSlug}/${_this.type}/meta`;
        }
        const response = yield _this.authenticatedFetch.fetch(endpoint, {
          headers: {
            'Accept-Language': locale
          }
        });
        if (!response || !response.ok) {
          throw new Error('[EMAIL] meta', response);
        }
        const {
          meta
        } = yield response.json();
        if (!meta) {
          throw new Error('[EMAIL] meta', response);
        }
        _this.meta = Object.assign(_this.meta || {}, {
          [locale]: meta
        });
        return (0, _rsvp.resolve)(meta);
      } catch (error) {
        return (0, _rsvp.reject)(error);
      }
    }();
  }), _class = class EmailCampaign extends _campaign.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "availableLanguages", _descriptor3, this);
      _initializerDefineProperty(this, "publishedLanguages", _descriptor4, this);
      _initializerDefineProperty(this, "language", _descriptor5, this);
      _initializerDefineProperty(this, "configurations", _descriptor6, this);
      _initializerDefineProperty(this, "isStartsAtEnabled", _descriptor7, this);
      _initializerDefineProperty(this, "startsAt", _descriptor8, this);
      _initializerDefineProperty(this, "isEndsAtEnabled", _descriptor9, this);
      _initializerDefineProperty(this, "endsAt", _descriptor10, this);
      _initializerDefineProperty(this, "name", _descriptor11, this);
      _initializerDefineProperty(this, "descriptions", _descriptor12, this);
      _initializerDefineProperty(this, "emailSeries", _descriptor13, this);
      _initializerDefineProperty(this, "contribution", _descriptor14, this);
      _initializerDefineProperty(this, "voucher", _descriptor15, this);
      _initializerDefineProperty(this, "landingPage", _descriptor16, this);
      _defineProperty(this, "transactionConfigurationChange", async (property, value) => {
        await this.uiTransactionManager.changeModelProperty(this, property, value, 'configurations', 'configuration');
      });
      /**
       * Fetches the meta data for a given locale
       * @type {Task}
       * @param {String} locale the locale of the meta data
       * @function fetchMeta
       */
      _initializerDefineProperty(this, "fetchMeta", _descriptor17, this);
    }
    get organizationSlug() {
      var _this$currentUser;
      return (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.currentOrganization) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.id;
    }

    // override default transactionUrl from UiTransactionableModel
    get transactionUrl() {
      return `${this.type}/${this.id}`;
    }
    get transactionCreateUrl() {
      return this.type;
    }

    // TODO: remove this getter and all it's usages when the new structure is the default
    get isNewStructure() {
      const newStructureTypes = ['pre-stay-campaign', 'convert-ota-to-direct-booker-campaign', 'reactivate-enquirer-without-booking-campaign', 'reactivate-lost-guest-campaign'];
      return newStructureTypes.includes(this.type);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "availableLanguages", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "publishedLanguages", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "language", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "configurations", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isStartsAtEnabled", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "startsAt", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isEndsAtEnabled", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "endsAt", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "descriptions", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "emailSeries", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "contribution", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "voucher", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "landingPage", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "fetchMeta", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});