define("additive-mc/mirage/routes/campaigns/on-page", ["exports", "additive-mc/mirage/routes/helpers/response", "faker", "ember-cli-mirage"], function (_exports, _response, _faker, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.OnPageRoutes = void 0;
  const OnPageRoutes = self => {
    self.get('/organisation-campaigns/on-page', _ref => {
      let {
        onPageCampaigns
      } = _ref;
      const campaigns = onPageCampaigns.all();
      return {
        onPageCampaigns: campaigns.models,
        meta: {
          total: campaigns.models.length
        }
      };
    });
    self.get('/organisation-campaigns/on-page/:id', (_ref2, request) => {
      let {
        onPageCampaigns
      } = _ref2;
      const {
        id
      } = request.params;
      return onPageCampaigns.find(id);
    });
    self.del('/organisation-campaigns/on-page/:id', (_ref3, request) => {
      let {
        onPageCampaigns
      } = _ref3;
      const {
        id
      } = request.params;
      onPageCampaigns.find(id).destroy();
    });
    self.post('/organisation-campaigns/on-page', (schema, request) => {
      let body = JSON.parse(request.requestBody);
      const {
        title,
        campaignCode
      } = body;
      return schema.onPageCampaigns.create({
        title,
        campaignCode
      });
    });
    self.put('/organisation-campaigns/on-page/:id', (_ref4, request) => {
      let {
        onPageCampaigns
      } = _ref4;
      const {
        id
      } = request.params;
      const body = JSON.parse(request.requestBody);
      return onPageCampaigns.find(id).update(body);
    });
    self.put('/organisation-campaigns/on-page/:id/activate', (schema, request) => {
      try {
        const model = onPageCampaignOrFail(schema, request);
        return model.update('active', true);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/on-page/:id/deactivate', (schema, request) => {
      try {
        const model = onPageCampaignOrFail(schema, request);
        return model.update('active', false);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.get('/organisation-campaigns/on-page/:id/contents', (schema, request) => {
      try {
        const campaign = onPageCampaignOrFail(schema, request);
        return campaign.contents;
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.post('/organisation-campaigns/on-page/:id/contents', (schema, request) => {
      try {
        const campaign = onPageCampaignOrFail(schema, request);
        const attrs = JSON.parse(request.requestBody);
        return schema.onPageContents.create(Object.assign(attrs, {
          campaign
        }));
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.get('/organisation-campaigns/on-page/:id/contents/:content_id', (schema, request) => {
      try {
        return contentOrFail(schema, request);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.del('/organisation-campaigns/on-page/:id/contents/:content_id', (schema, request) => {
      try {
        const model = contentOrFail(schema, request);
        return model.destroy();
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/on-page/:id/contents/:content_id', (schema, request) => {
      try {
        const model = contentOrFail(schema, request);
        const attrs = JSON.parse(request.requestBody);
        return model.update(attrs);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.del('/organisation-campaigns/on-page/:id/contents/:content_id/:language', (schema, request) => {
      try {
        const {
          language
        } = request.params;
        if (['de', 'en', 'it'].indexOf(language) === -1) {
          throw (0, _response.notFoundResponse)(`'${language}' not supported`);
        }
        const model = contentOrFail(schema, request);
        if (model.availableLanguages.indexOf(language) === -1) {
          return (0, _emberCliMirage.Response)(400, {}, {
            message: `'${language}' does not exist`
          });
        }
        model.availableLanguages.splice(model.availableLanguages.indexOf(language), 1);
        return model;
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/on-page/:id/contents/:content_id/activate', (schema, request) => {
      try {
        const model = contentOrFail(schema, request);
        return model.update('active', true);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/on-page/:id/contents/:content_id/deactivate', (schema, request) => {
      try {
        const model = contentOrFail(schema, request);
        return model.update('active', false);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });

    /* Voucher */

    self.get('/organisation-campaigns/on-page/:id/vouchers/:voucher_id', (schema, request) => {
      try {
        return voucherOrFail(schema, request);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/on-page/:id/vouchers/:voucher_id', (schema, request) => {
      try {
        const model = voucherOrFail(schema, request);
        const attrs = JSON.parse(request.requestBody);
        return model.update(Object.assign({
          image: null
        }, attrs));
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.del('/organisation-campaigns/on-page/:id/vouchers/:voucher_id/:language', (schema, request) => {
      try {
        const {
          language
        } = request.params;
        if (['de', 'en', 'it'].indexOf(language) === -1) {
          throw (0, _response.notFoundResponse)(`'${language}' not supported`);
        }
        const model = voucherOrFail(schema, request);
        if (model.availableLanguages.indexOf(language) === -1) {
          return (0, _emberCliMirage.Response)(400, {}, {
            message: `'${language}' does not exist`
          });
        }
        model.availableLanguages.splice(model.availableLanguages.indexOf(language), 1);
        return model;
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/on-page/:id/vouchers/:voucher_id/activate', (schema, request) => {
      try {
        const model = voucherOrFail(schema, request);
        return model.update('active', true);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/on-page/:id/vouchers/:voucher_id/deactivate', (schema, request) => {
      try {
        const model = voucherOrFail(schema, request);
        return model.update('active', false);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
  };
  _exports.OnPageRoutes = OnPageRoutes;
  const onPageCampaignOrFail = (schema, request) => {
    const {
      id
    } = request.params;
    const model = schema.onPageCampaigns.find(id);
    if (!model) {
      throw new Error('not_found');
    }
    return model;
  };
  const contentOrFail = (schema, request) => {
    const campaign = onPageCampaignOrFail(schema, request);
    const {
      content_id
    } = request.params;
    const model = schema.onPageContents.find(content_id);
    if (!model || model.campaignId !== campaign.id) {
      throw new Error('not_found');
    }
    return model;
  };
  const voucherOrFail = (schema, request) => {
    const {
      voucher_id
    } = request.params;
    const model = schema.onPageCampaignVouchers.find(voucher_id);
    if (!model) {
      throw new Error('not_found');
    }
    return model;
  };
});