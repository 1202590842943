define("additive-mc/components/amc-campaign/test-email-dialog", ["exports", "@ember/component", "@ember/template-factory", "additive-mc/config/environment", "@glimmer/component", "@glimmer/tracking", "@ember/service", "ember-concurrency", "@ember/object/computed", "ember-arg-types", "prop-types", "ember-changeset", "additive-mc/validations/email-campaign", "ember-changeset-validations"], function (_exports, _component, _templateFactory, _environment, _component2, _tracking, _service, _emberConcurrency, _computed, _emberArgTypes, _propTypes, _emberChangeset, _emailCampaign, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiTagsInputModal
    @title={{t "testEmail.title"}}
    @description={{if this.isBulk (t "testEmail.descriptionBulk") (t "testEmail.description")}}
    @onClose={{this.onClose}}
    @showDiscardChangesConfirm={{false}}
    @changeset={{this.changeset}}
    @changesetProperty={{"email"}}
    @buttonLabel={{t "testEmail.send"}}
    @onSave={{perform this.bulkSendTestEmail this.changeset.email}} as |modal|
  >
    <modal.top>
      <div class="flex flex-column">
        <div class="mb2">
          <UiLangSelect
            @languages={{this._availableLanguages}}
            @publishedLanguages={{this._contentLanguages}}
            @hideLanguageActions={{true}}
            @selected={{this._currentLanguage}}
            @onSelect={{fn (mut this._currentLanguage)}}
          />
        </div>
      </div>
    </modal.top>
  </UiTagsInputModal>
  */
  {
    "id": "Xcy8XDgz",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@description\",\"@onClose\",\"@showDiscardChangesConfirm\",\"@changeset\",\"@changesetProperty\",\"@buttonLabel\",\"@onSave\"],[[28,[37,1],[\"testEmail.title\"],null],[52,[30,0,[\"isBulk\"]],[28,[37,1],[\"testEmail.descriptionBulk\"],null],[28,[37,1],[\"testEmail.description\"],null]],[30,0,[\"onClose\"]],false,[30,0,[\"changeset\"]],\"email\",[28,[37,1],[\"testEmail.send\"],null],[28,[37,3],[[30,0,[\"bulkSendTestEmail\"]],[30,0,[\"changeset\",\"email\"]]],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"top\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"flex flex-column\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"mb2\"],[12],[1,\"\\n        \"],[8,[39,4],null,[[\"@languages\",\"@publishedLanguages\",\"@hideLanguageActions\",\"@selected\",\"@onSelect\"],[[30,0,[\"_availableLanguages\"]],[30,0,[\"_contentLanguages\"]],true,[30,0,[\"_currentLanguage\"]],[28,[37,5],[[28,[37,6],[[30,0,[\"_currentLanguage\"]]],null]],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"modal\"],false,[\"ui-tags-input-modal\",\"t\",\"if\",\"perform\",\"ui-lang-select\",\"fn\",\"mut\"]]",
    "moduleName": "additive-mc/components/amc-campaign/test-email-dialog.hbs",
    "isStrictMode": false
  });
  let AmcCampaignTestEmailDialogComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec3 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.string), _dec6 = (0, _computed.alias)('uiAppSettings.languages.contentLanguages'), _dec7 = (0, _computed.alias)('currentUser.user'), _dec8 = (0, _emberConcurrency.task)(function* (email) {
    try {
      var _this$_user;
      this.isTouched = true;
      const {
        model,
        changeset
      } = this;
      yield changeset.validate();
      if (!changeset.get('isValid')) return;
      let url;
      if (this.isTransaction) {
        const {
          baseUrl,
          transactionId
        } = this.uiTransactionManager;
        url = `${baseUrl}/${transactionId}/${model.transactionUrl}/${this.isBulk ? 'test-emails' : 'test-email'}`;
      } else if (this.isNewStructure) {
        const baseUrl = `${_environment.default.APP.apiBaseHostV2}/${this.organizationSlug}/${this.campaignType}/${this.campaignId}`;
        if (model.constructor.modelName === 'email') {
          url = `${baseUrl}/email-series/${model.emailSeriesId}/emails/${model.id}/test-email`;
        } else {
          url = this.isBulk ? `${baseUrl}/test-emails` : `${baseUrl}/test-email`;
        }
      } else {
        const adapter = this.store.adapterFor(this.campaignType || model.constructor.modelName);
        const baseUrl = adapter.urlForFindAll(this.campaignType || model.constructor.modelName, {
          adapterOptions: {
            campaignId: model.emailSeriesId ? model.campaignId : model.id
          }
        });
        url = model.emailSeriesId ? `${baseUrl}/${model.id}/test-email` : `${baseUrl}/test-email`;
      }
      const body = {
        language: this._currentLanguage,
        receiver: Array.isArray(email) && email.join(',')
      };
      if (this.isTransaction || this.isNewStructure) {
        delete body.language;
        delete body.receiver;
        body.recipients = email;
      }
      const options = {
        method: this.isTransaction || this.isNewStructure ? 'POST' : 'PUT',
        body: JSON.stringify(body)
      };
      if (this.isTransaction || this.isNewStructure) {
        options.headers = {
          'Accept-Language': this._currentLanguage
        };
      }
      const response = yield this.authenticatedFetch.fetch(url, options);
      if (!(response !== null && response !== void 0 && response.ok)) {
        throw new Error('Response not ok');
      }

      // saves the email in the localStorage with user ID as key
      yield localStorage.setItem(`${(_this$_user = this._user) === null || _this$_user === void 0 ? void 0 : _this$_user.id}-email`, email);
      this.createChangeset();
      this.onClose();
      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('toast.success')
      });
    } catch (e) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('toast.unexpectedError'),
        duration: 2500
      });
    }
  }), _dec9 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AmcCampaignTestEmailDialogComponent extends _component2.default {
    get _availableLanguages() {
      var _this$model;
      return ((_this$model = this.model) === null || _this$model === void 0 ? void 0 : _this$model.availableLanguages) || this._contentLanguages;
    }

    // TODO: remove this getter and all it's usages when the new structure is the default
    get isNewStructure() {
      var _this$model2;
      if ((_this$model2 = this.model) !== null && _this$model2 !== void 0 && _this$model2.isNewStructure) {
        return true;
      }
      const newStructureTypes = ['pre-stay-campaign', 'convert-ota-to-direct-booker-campaign', 'reactivate-enquirer-without-booking-campaign', 'trigger-campaign', 'reactivate-lost-guest-campaign'];
      return newStructureTypes.includes(this.campaignType);
    }
    get organizationSlug() {
      var _this$currentUser;
      return (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.currentOrganization) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.id;
    }
    constructor() {
      var _this$model3;
      super(...arguments);
      _initializerDefineProperty(this, "uiTransactionManager", _descriptor, this);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor2, this);
      _initializerDefineProperty(this, "currentUser", _descriptor3, this);
      _initializerDefineProperty(this, "uiToast", _descriptor4, this);
      _initializerDefineProperty(this, "store", _descriptor5, this);
      _initializerDefineProperty(this, "intl", _descriptor6, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor7, this);
      /**
       * the email campaign
       *
       * @argument model
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "model", _descriptor8, this);
      /**
       * whether multiple test-emails will be send
       *
       * @argument isBulk
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isBulk", _descriptor9, this);
      /**
       * whether its a transaction
       *
       * @argument isTransaction
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isTransaction", _descriptor10, this);
      /**
       * the campaign type that can be manually set
       *
       * @argument campaignType
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "campaignType", _descriptor11, this);
      /**
       * the campaign id
       *
       * @argument campaignId
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "campaignId", _descriptor12, this);
      /**
       * the changeset for the model
       *
       * @property changeset
       * @type {Changeset}
       */
      _initializerDefineProperty(this, "changeset", _descriptor13, this);
      /**
       * whether the input should be considered as touched
       *
       * @property isTouched
       * @type {Boolean}
       */
      _defineProperty(this, "isTouched", false);
      /**
       * Current selected language
       *
       * @property _currentLanguage
       * @type {String}
       * @private
       */
      _initializerDefineProperty(this, "_currentLanguage", _descriptor14, this);
      _initializerDefineProperty(this, "_contentLanguages", _descriptor15, this);
      _initializerDefineProperty(this, "_user", _descriptor16, this);
      /**
       * Sends all emails for this campaign as test email to the given email
       * @function sendTestEmail
       * @type {Task}
       */
      _initializerDefineProperty(this, "bulkSendTestEmail", _descriptor17, this);
      if ((_this$model3 = this.model) !== null && _this$model3 !== void 0 && _this$model3.language) {
        this._currentLanguage = this.model.language;
      }
      this.createChangeset();
    }
    createChangeset() {
      var _this$_user2;
      const savedEmail = localStorage.getItem(`${(_this$_user2 = this._user) === null || _this$_user2 === void 0 ? void 0 : _this$_user2.id}-email`);
      const emailArray = savedEmail === null || savedEmail === void 0 ? void 0 : savedEmail.split(',');
      const validation = (0, _emailCampaign.testEmailValidation)(this.intl);
      const changeset = new _emberChangeset.default({
        email: emailArray || []
      }, (0, _emberChangesetValidations.default)(validation), validation);
      this.changeset = changeset;
    }
    onClose() {}
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uiTransactionManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isBulk", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isTransaction", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "campaignType", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "campaignId", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "_currentLanguage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'de';
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "_contentLanguages", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "_user", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "bulkSendTestEmail", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcCampaignTestEmailDialogComponent);
});