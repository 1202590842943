define("additive-mc/controllers/instance/campaigns/ad/catalog-ads/index/catalog-ad", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-concurrency", "ember-animated"], function (_exports, _controller, _object, _service, _tracking, _emberConcurrency, _emberAnimated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceCampaignsAdCatalogAdsCatalogAdController = _exports.default = (_dec = (0, _object.computed)('model.catalogAd.optimizationEvent', 'meta.optimizationEvents.[]'), _dec2 = (0, _object.computed)('model.catalogAd.potentialTravelGroup', 'meta.potentialTravelGroups.[]'), _dec3 = (0, _object.computed)('model.catalogAd.targetAudience', 'meta.targetAudiences.[]'), _dec4 = (0, _object.computed)('model.catalogAd.{budget.amount,endDate,startDate,budgetSpend.value}', 'campaignOptimizationEvent', 'potentialTravelGroup', 'targetAudience'), _dec5 = (0, _emberConcurrency.task)(function* () {}), _dec6 = (0, _emberConcurrency.task)(function* () {
    if (!this.model.catalogAd.isDraft) {
      return;
    }
    const response = yield this.model.catalogAd.validate(this.adapterOptions);
    if (response.ok) {
      this.isValid = true;
      return;
    }
    const {
      validations
    } = yield response.json();
    this.isValid = (validations === null || validations === void 0 ? void 0 : validations.length) > 0 ? false : true;
    this.validations = validations;
  }), _dec7 = (0, _emberConcurrency.task)(function* () {
    try {
      yield this.model.catalogAd.destroyRecord({
        adapterOptions: this.adapterOptions
      });
      this.router.transitionTo('instance.campaigns.ad.catalog-ads', this.campaignId);
      yield this.uiFilter.reset('catalog-ads');
      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('global.toast.success.savedChanges')
      });
    } catch (e) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toast.error.savedChanges')
      });
    }
  }), _class = class InstanceCampaignsAdCatalogAdsCatalogAdController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "store", _descriptor5, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor6, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor7, this);
      _initializerDefineProperty(this, "uiToast", _descriptor8, this);
      _initializerDefineProperty(this, "uiFilter", _descriptor9, this);
      _initializerDefineProperty(this, "meta", _descriptor10, this);
      _initializerDefineProperty(this, "isLoading", _descriptor11, this);
      _initializerDefineProperty(this, "isError", _descriptor12, this);
      _initializerDefineProperty(this, "campaignId", _descriptor13, this);
      _initializerDefineProperty(this, "depictionMeta", _descriptor14, this);
      _initializerDefineProperty(this, "validations", _descriptor15, this);
      _initializerDefineProperty(this, "isValid", _descriptor16, this);
      _defineProperty(this, "_animatedTransition", _emberAnimated.fade);
      /**
       * Fetches and returns the depictions meta
       * Is replaced in setupController by task of parent controller
       *
       * @function getDepictionMeta
       * @type {Task}
       */
      _initializerDefineProperty(this, "getDepictionMeta", _descriptor17, this);
      _initializerDefineProperty(this, "validate", _descriptor18, this);
      _initializerDefineProperty(this, "deleteAd", _descriptor19, this);
    }
    get isReadOnly() {
      return this.currentUser.isReadOnly;
    }
    get campaignOptimizationEvent() {
      var _this$meta;
      if (!this.model.catalogAd.optimizationEvent) return null;
      const campaignOptimizationEvent = (_this$meta = this.meta) === null || _this$meta === void 0 || (_this$meta = _this$meta.optimizationEvents) === null || _this$meta === void 0 ? void 0 : _this$meta.find(campaignOptimizationEvent => campaignOptimizationEvent.value === this.model.catalogAd.optimizationEvent);
      return campaignOptimizationEvent === null || campaignOptimizationEvent === void 0 ? void 0 : campaignOptimizationEvent.label;
    }
    get formattedDepictions() {
      if (!this.depictionMeta) {
        return [];
      }
      return this.model.depictions.map(depiction => {
        var _this$depictionMeta$c;
        const ctaText = (_this$depictionMeta$c = this.depictionMeta.ctaTypes.find(ctaType => ctaType.value === depiction.ctaType)) === null || _this$depictionMeta$c === void 0 ? void 0 : _this$depictionMeta$c.label;
        const depictionObject = {
          headline: depiction.headline,
          description: depiction.description,
          text: depiction.text,
          ctaText: ctaText
        };
        return depictionObject;
      });
    }
    get formattedPromotedProducts() {
      var _this$meta2, _this$model$catalogAd;
      if (!((_this$meta2 = this.meta) !== null && _this$meta2 !== void 0 && _this$meta2.productTypes)) {
        return [];
      }
      return (_this$model$catalogAd = this.model.catalogAd.promotedProducts) === null || _this$model$catalogAd === void 0 ? void 0 : _this$model$catalogAd.map(promotedProduct => {
        var _promotedProduct$spec;
        const metaProductType = this.meta.productTypes.find(productType => productType.value === promotedProduct.value);
        promotedProduct.label = `${metaProductType === null || metaProductType === void 0 ? void 0 : metaProductType.label}${promotedProduct !== null && promotedProduct !== void 0 && (_promotedProduct$spec = promotedProduct.specifiedSelection) !== null && _promotedProduct$spec !== void 0 && _promotedProduct$spec.length ? ':' : ''}`;
        promotedProduct.selection = promotedProduct.specifiedSelection.map(selection => {
          var _metaProductType$subT;
          return (_metaProductType$subT = metaProductType.subTypes.find(subType => subType.value === selection)) === null || _metaProductType$subT === void 0 ? void 0 : _metaProductType$subT.label;
        }).join(', ');
        return promotedProduct;
      });
    }
    get potentialTravelGroup() {
      var _this$meta3;
      if (!this.model.catalogAd.potentialTravelGroup) return null;
      const potentialTravelGroup = (_this$meta3 = this.meta) === null || _this$meta3 === void 0 || (_this$meta3 = _this$meta3.potentialTravelGroups) === null || _this$meta3 === void 0 ? void 0 : _this$meta3.find(potentialTravelGroup => potentialTravelGroup.value === this.model.catalogAd.potentialTravelGroup);
      return potentialTravelGroup === null || potentialTravelGroup === void 0 ? void 0 : potentialTravelGroup.label;
    }
    get targetAudience() {
      var _this$meta4;
      if (!this.model.catalogAd.targetAudience) return null;
      const targetAudience = (_this$meta4 = this.meta) === null || _this$meta4 === void 0 || (_this$meta4 = _this$meta4.targetAudiences) === null || _this$meta4 === void 0 ? void 0 : _this$meta4.find(targetAudience => targetAudience.value === this.model.catalogAd.targetAudience);
      return targetAudience === null || targetAudience === void 0 ? void 0 : targetAudience.label;
    }
    get showNotification() {
      return this.model.catalogAd.isDraft && !this.isValid && !this.validate.isRunning;
    }
    get adapterOptions() {
      return {
        campaignId: this.campaignId,
        type: 'catalog-ads'
      };
    }
    get budgetType() {
      return this.intl.t(`adCampaigns.catalogAds.totalBudget.${this.model.catalogAd.budget.type}`);
    }
    get showContentInfo() {
      const {
        budget,
        endDate,
        startDate,
        budgetSpend
      } = this.model.catalogAd;
      return (budget === null || budget === void 0 ? void 0 : budget.amount) || endDate || startDate || (budgetSpend === null || budgetSpend === void 0 ? void 0 : budgetSpend.value) || this.targetAudience || this.campaignOptimizationEvent || this.potentialTravelGroup;
    }
    back() {
      this.router.transitionTo('instance.campaigns.ad.catalog-ads');
    }
    openEdit() {
      this.router.transitionTo('instance.campaigns.ad.catalog-ads.index.catalog-ad.edit');
    }
    onPublish() {
      const publishAction = this.model.catalogAd.isDraft ? 'publish' : 'unpublish';
      this.uiDialog.showConfirm(this.intl.t(`adCampaigns.catalogAds.${publishAction}.dialog.title`), this.intl.t(`adCampaigns.catalogAds.${publishAction}.dialog.description`), () => this.modelAction(publishAction), this.intl.t(`global.actions.${publishAction}`), false);
    }
    async modelAction(publishAction) {
      let toast;
      try {
        this.isLoading = true;
        toast = this.uiToast.showToast({
          title: this.intl.t(`adCampaigns.catalogAds.publish.loading.${publishAction}`),
          description: this.intl.t('adCampaigns.catalogAds.publish.loading.text'),
          type: 'loading',
          duration: -1
        });
        const response = await this.model.catalogAd[publishAction](this.adapterOptions);
        if (!(response !== null && response !== void 0 && response.ok)) {
          // show error dialog
          const {
            message
          } = await response.json();
          throw new Error(message);
        }
        this.uiToast.destroyToast(toast);
        const advertisement = await response.json();
        const serializer = this.store.serializerFor('advertisement');
        serializer.normalizeResponse(null, null, advertisement);
        this.store.pushPayload(advertisement);
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      } catch (e) {
        this.uiToast.destroyToast(toast);
        this.uiDialog.showError(null, e.message);
      } finally {
        this.isLoading = false;
      }
    }
    onDelete() {
      if (this.isReadOnly) {
        return;
      }
      this.uiDialog.showConfirm(this.intl.t('adCampaigns.catalogAds.delete.title'), this.intl.t('adCampaigns.catalogAds.delete.description'), async () => {
        await this.delete();
      }, this.intl.t('global.actions.delete'), true, true);
    }
    async delete() {
      await this.deleteAd.perform();
    }
    onArchive() {
      this.uiDialog.showConfirm(this.intl.t('adCampaigns.catalogAds.archive.title'), this.intl.t('adCampaigns.catalogAds.archive.description'), () => this.archive(), this.intl.t('global.actions.archive'), false, true);
    }
    async archive() {
      try {
        const advertisement = await this.model.catalogAd.archive(this.adapterOptions);
        const serializer = this.store.serializerFor('advertisement');
        serializer.normalizeResponse(null, null, advertisement);
        this.store.pushPayload(advertisement);
      } catch (e) {
        this.uiDialog.showError(null, e.message);
      }
    }
    onExpiredEdit() {
      this.uiDialog.showConfirm(this.intl.t('adCampaigns.catalogAds.editExpired.title'), this.intl.t('adCampaigns.catalogAds.editExpired.description'), () => this.modelAction('unpublish'), this.intl.t('adCampaigns.catalogAds.editExpired.action'), false);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "uiFilter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "meta", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "campaignId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "depictionMeta", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "validations", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "isValid", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "campaignOptimizationEvent", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "campaignOptimizationEvent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "potentialTravelGroup", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "potentialTravelGroup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "targetAudience", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "targetAudience"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showContentInfo", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "showContentInfo"), _class.prototype), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "getDepictionMeta", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "validate", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "back", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "back"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openEdit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onPublish", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onPublish"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "modelAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "modelAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDelete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDelete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "deleteAd", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onArchive", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onArchive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "archive", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "archive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onExpiredEdit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onExpiredEdit"), _class.prototype), _class);
});