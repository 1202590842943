define("additive-mc/mirage/routes/campaigns/referral", ["exports", "additive-mc/mirage/routes/helpers/response", "faker", "ember-cli-mirage"], function (_exports, _response, _faker, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ReferralRoutes = void 0;
  const ReferralRoutes = self => {
    self.get('/organisation-campaigns/referral', _ref => {
      let {
        referralCampaigns
      } = _ref;
      const campaigns = referralCampaigns.all();
      return {
        referralCampaigns: campaigns.models,
        meta: {
          total: campaigns.models.length
        }
      };
    });
    self.get('/organisation-campaigns/referral/:id', (_ref2, request) => {
      let {
        referralCampaigns
      } = _ref2;
      const {
        id
      } = request.params;
      return referralCampaigns.find(id);
    });
    self.del('/organisation-campaigns/referral/:id', (_ref3, request) => {
      let {
        referralCampaigns
      } = _ref3;
      const {
        id
      } = request.params;
      referralCampaigns.find(id).destroy();
    });
    self.post('/organisation-campaigns/referral', (schema, request) => {
      let body = JSON.parse(request.requestBody);
      const {
        title,
        campaignCode
      } = body;
      return schema.referralCampaigns.create({
        title,
        campaignCode
      });
    });
    self.put('/organisation-campaigns/referral/:id', (_ref4, request) => {
      let {
        referralCampaigns
      } = _ref4;
      const {
        id
      } = request.params;
      const body = JSON.parse(request.requestBody);
      return referralCampaigns.find(id).update(body);
    });
    self.put('/organisation-campaigns/referral/:id/activate', (schema, request) => {
      try {
        const model = referralCampaignOrFail(schema, request);
        return model.update('active', true);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/referral/:id/deactivate', (schema, request) => {
      try {
        const model = referralCampaignOrFail(schema, request);
        return model.update('active', false);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });

    /* Voucher */

    self.get('/organisation-campaigns/referral/:id/vouchers/:voucher_id', (schema, request) => {
      try {
        return voucherOrFail(schema, request);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/referral/:id/vouchers/:voucher_id', (schema, request) => {
      try {
        const model = voucherOrFail(schema, request);
        const attrs = JSON.parse(request.requestBody);
        return model.update(Object.assign({
          image: null
        }, attrs));
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.del('/organisation-campaigns/referral/:id/vouchers/:voucher_id/:language', (schema, request) => {
      try {
        const {
          language
        } = request.params;
        if (['de', 'en', 'it'].indexOf(language) === -1) {
          throw (0, _response.notFoundResponse)(`'${language}' not supported`);
        }
        const model = voucherOrFail(schema, request);
        if (model.availableLanguages.indexOf(language) === -1) {
          return (0, _emberCliMirage.Response)(400, {}, {
            message: `'${language}' does not exist`
          });
        }
        model.availableLanguages.splice(model.availableLanguages.indexOf(language), 1);
        return model;
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/referral/:id/vouchers/:voucher_id/activate', (schema, request) => {
      try {
        const model = voucherOrFail(schema, request);
        return model.update('active', true);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/referral/:id/vouchers/:voucher_id/deactivate', (schema, request) => {
      try {
        const model = voucherOrFail(schema, request);
        return model.update('active', false);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });

    /* Landingpage */

    self.get('/organisation-campaigns/referral/:id/landingpages/:landingpage_id', (schema, request) => {
      try {
        return landingpageOrFail(schema, request);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/referral/:id/landingpages/:landingpage_id', (schema, request) => {
      try {
        const model = landingpageOrFail(schema, request);
        const attrs = JSON.parse(request.requestBody);
        return model.update(Object.assign(attrs));
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.del('/organisation-campaigns/referral/:id/landingpages/:landingpage_id/:language', (schema, request) => {
      try {
        const {
          language
        } = request.params;
        if (['de', 'en', 'it'].indexOf(language) === -1) {
          throw (0, _response.notFoundResponse)(`'${language}' not supported`);
        }
        const model = landingpageOrFail(schema, request);
        if (model.availableLanguages.indexOf(language) === -1) {
          return (0, _emberCliMirage.Response)(400, {}, {
            message: `'${language}' does not exist`
          });
        }
        model.availableLanguages.splice(model.availableLanguages.indexOf(language), 1);
        return model;
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/referral/:id/landingpages/:landingpage_id/activate', (schema, request) => {
      try {
        const model = landingpageOrFail(schema, request);
        return model.update('active', true);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/referral/:id/landingpages/:landingpage_id/deactivate', (schema, request) => {
      try {
        const model = landingpageOrFail(schema, request);
        return model.update('active', false);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
  };
  _exports.ReferralRoutes = ReferralRoutes;
  const referralCampaignOrFail = (schema, request) => {
    const {
      id
    } = request.params;
    const model = schema.referralCampaigns.find(id);
    if (!model) {
      throw new Error('not_found');
    }
    return model;
  };
  const voucherOrFail = (schema, request) => {
    const {
      voucher_id
    } = request.params;
    const model = schema.referralCampaignVouchers.find(voucher_id);
    if (!model) {
      throw new Error('not_found');
    }
    return model;
  };
  const landingpageOrFail = (schema, request) => {
    const {
      landingpage_id
    } = request.params;
    const model = schema.referralLandingpages.find(landingpage_id);
    if (!model) {
      throw new Error('not_found');
    }
    return model;
  };
});