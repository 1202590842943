define("additive-mc/mirage/routes/campaigns/index", ["exports", "additive-mc/mirage/routes/campaigns/ad", "additive-mc/mirage/routes/campaigns/trigger-email", "additive-mc/mirage/routes/campaigns/email", "additive-mc/mirage/routes/campaigns/social", "additive-mc/mirage/routes/campaigns/lead-ad", "additive-mc/mirage/routes/campaigns/catalog-ad", "additive-mc/mirage/routes/campaigns/content-ad", "additive-mc/mirage/routes/campaigns/on-page", "additive-mc/mirage/routes/campaigns/referral", "additive-mc/mirage/routes/campaigns/search-ad", "additive-mc/mirage/routes/campaigns/tiktok-lead-ad"], function (_exports, _ad, _triggerEmail, _email, _social, _leadAd, _catalogAd, _contentAd, _onPage, _referral, _searchAd, _tiktokLeadAd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "AdRoutes", {
    enumerable: true,
    get: function get() {
      return _ad.AdRoutes;
    }
  });
  Object.defineProperty(_exports, "CatalogAdRoutes", {
    enumerable: true,
    get: function get() {
      return _catalogAd.CatalogAdRoutes;
    }
  });
  Object.defineProperty(_exports, "EmailRoutes", {
    enumerable: true,
    get: function get() {
      return _email.EmailRoutes;
    }
  });
  Object.defineProperty(_exports, "FacebookAdRoutes", {
    enumerable: true,
    get: function get() {
      return _contentAd.FacebookAdRoutes;
    }
  });
  Object.defineProperty(_exports, "LeadAdRoutes", {
    enumerable: true,
    get: function get() {
      return _leadAd.LeadAdRoutes;
    }
  });
  Object.defineProperty(_exports, "OnPageRoutes", {
    enumerable: true,
    get: function get() {
      return _onPage.OnPageRoutes;
    }
  });
  Object.defineProperty(_exports, "ReferralRoutes", {
    enumerable: true,
    get: function get() {
      return _referral.ReferralRoutes;
    }
  });
  Object.defineProperty(_exports, "SearchAdRoutes", {
    enumerable: true,
    get: function get() {
      return _searchAd.SearchAdRoutes;
    }
  });
  Object.defineProperty(_exports, "SocialRoutes", {
    enumerable: true,
    get: function get() {
      return _social.SocialRoutes;
    }
  });
  Object.defineProperty(_exports, "TikTokLeadAdsRoutes", {
    enumerable: true,
    get: function get() {
      return _tiktokLeadAd.TikTokLeadAdsRoutes;
    }
  });
  Object.defineProperty(_exports, "TriggerEmailRoutes", {
    enumerable: true,
    get: function get() {
      return _triggerEmail.TriggerEmailRoutes;
    }
  });
});