define("additive-mc/routes/instance/campaigns/email/detail/index/edit", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceCampaignsEmailDetailEdit = _exports.default = (_class = class InstanceCampaignsEmailDetailEdit extends _route.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor4, this);
      _initializerDefineProperty(this, "uiTransactionManager", _descriptor5, this);
    }
    async setupController(controller) {
      super.setupController(...arguments);
      controller._isLoading = true;

      // eslint-disable-next-line ember/no-controller-access-in-routes
      const detailViewController = this.controllerFor('instance.campaigns.email.detail');
      controller.openPublishDialog = detailViewController.openPublishDialog;
      const {
        model
      } = controller;
      if (!this.uiTransactionManager.hasTransactionId) {
        try {
          await this.uiTransactionManager.beginTransaction(model.type, model.id);
        } catch (error) {
          this.uiDialog.showError();
          this.router.transitionTo('instance.campaigns.email.detail', model);
        }
        if (model.isNew) {
          await model.transactionCreateRecord();
        }
      }
      await controller.fetchCorporateDesign.perform();
      if (model.isNew && model.emailSeries.length === 0) {
        // Automatically create first email-series
        const firstEmailSeries = {
          label: null,
          offsetInDays: 0
        };
        await controller.createEmailSeries(firstEmailSeries, true);
      }
      controller._isLoading = false;
    }
    resetController(controller) {
      const {
        model
      } = controller;
      controller._surveys = null;
      controller._referralCampaigns = null;
      if (model.language && model.language !== this.uiAppSettings.currentLocale) {
        this.uiAppSettings.setLocale(model.language);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiTransactionManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});