define("additive-mc/components/amc-campaign/social-dialog", ["exports", "additive-mc/config/environment", "@ember/component", "@ember/object", "@ember/service", "ember-concurrency", "@ember/object/computed", "ember-animated"], function (_exports, _environment, _component, _object, _service, _emberConcurrency, _computed, _emberAnimated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    store: (0, _service.inject)(),
    uiFilter: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    tagName: '',
    _animatedTransition: _emberAnimated.fade,
    organizationSlug: (0, _computed.alias)('currentUser.currentOrganization.id'),
    onClose() {},
    getAvailableCampaigns: (0, _emberConcurrency.task)(function* () {
      const tasks = [];
      const baseUrl = _environment.default.APP.apiBaseHost;
      const base = `${baseUrl}/${this.organizationSlug}/organisation-campaigns/social/available`;
      try {
        tasks.push(this.authenticatedFetch.fetch(base));
        tasks.push((0, _emberConcurrency.timeout)(250));
        const [response] = yield (0, _emberConcurrency.all)(tasks);
        if (response && response.ok) {
          const data = yield response.json();
          (0, _object.set)(this, 'availableCampaigns', data);
          yield (0, _emberConcurrency.timeout)(100);
        } else {
          const {
            errors
          } = yield response.json();
          throw new Error(`${errors}`);
        }
      } catch (error) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }).on('init'),
    addCampaign: (0, _emberConcurrency.task)(function* (campaignCode) {
      const tasks = [];
      const title = this.intl.t(`socialCampaigns.${campaignCode}.title`);
      const modelRef = this.store.createRecord('social-campaign', {
        title,
        campaignCode
      });
      try {
        tasks.push(modelRef.save());
        tasks.push((0, _emberConcurrency.timeout)(250));
        const [response] = yield (0, _emberConcurrency.all)(tasks);
        const data = yield response;
        yield this.uiFilter.addItem('social', data);
        yield this.onClose();
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      } catch (error) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    })
  });
});