define("additive-mc/routes/instance/campaigns/search-ad/index/search-ad/edit", ["exports", "@ember/routing/route", "@additive-apps/ui/mixins/ui-full-width-view", "@ember/object"], function (_exports, _route, _uiFullWidthView, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InstanceCampaignsSearchAdIndexSearchAdEditRoute extends _route.default.extend(_uiFullWidthView.default) {
    model() {
      return super.model(...arguments);
    }
    setupController(controller) {
      super.setupController(...arguments);
      const {
        searchAdCampaign,
        meta
      } = this.modelFor('instance.campaigns.search-ad.index.search-ad');
      (0, _object.set)(controller, 'meta', meta);
      (0, _object.set)(controller, 'campaignId', searchAdCampaign.id);
      if (!controller.model.searchAdCampaign.budget) {
        controller.model.searchAdCampaign.budget = {};
      }
      controller.setupChangeset();
      controller.fetchExtensions.perform();
      controller.fetchMetadata.perform();
      controller.fetchTargetGroups.perform();
    }
    resetController(controller) {
      controller.changeset = null;
    }
    renderTemplate() {
      this.render('instance/campaigns/search-ad/index/search-ad/edit', {
        into: 'application'
      });
    }
  }
  _exports.default = InstanceCampaignsSearchAdIndexSearchAdEditRoute;
});