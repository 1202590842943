define("additive-mc/templates/components/amc-campaign/social-dialog", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "uo4vxvIy",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@isSimpleDialog\",\"@onClose\"],[[28,[37,1],[\"campaigns.new.social\"],null],true,[30,0,[\"onClose\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],[[24,0,\"ohidden\"]],null,[[\"default\"],[[[[1,\"\\n\"],[6,[39,3],[[30,0,[\"getAvailableCampaigns\",\"isRunning\"]]],[[\"animation\"],[[30,0,[\"_animatedTransition\"]]]],[[\"default\",\"else\"],[[[[1,\"        \"],[10,0],[14,0,\"flex h5 items-center justify-center pt2\"],[12],[1,\"\\n          \"],[8,[39,4],null,null,null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[42,[28,[37,6],[[28,[37,6],[[30,0,[\"availableCampaigns\"]]],null]],null],null,[[[1,\"          \"],[8,[39,7],null,[[\"@onClick\",\"@class\"],[[28,[37,8],[[30,0,[\"addCampaign\"]],[30,2]],null],\"br1 ohidden\"]],[[\"default\"],[[[[1,\"\\n            \"],[10,0],[14,0,\"pa2 br1 flex-grow-1\"],[12],[1,\"\\n              \"],[10,0],[14,0,\"font-sm primary\"],[12],[1,\"\\n                \"],[1,[28,[35,1],[[28,[37,9],[\"socialCampaigns.\",[30,2],\".title\"],null]],null]],[1,\"\\n              \"],[13],[1,\"\\n              \"],[10,0],[14,0,\"font-xs secondary\"],[12],[1,\"\\n                \"],[1,[30,2]],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[2]],null]],[]]]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"d\",\"campaign\"],false,[\"ui-create-content-dialog\",\"t\",\"animated-container\",\"animated-if\",\"ui-loading\",\"each\",\"-track-array\",\"ui-item\",\"perform\",\"concat\"]]",
    "moduleName": "additive-mc/templates/components/amc-campaign/social-dialog.hbs",
    "isStrictMode": false
  });
});